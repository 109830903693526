// admin/src/Component/OutOfHomeCampaign/Create.js

import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    required
} from 'react-admin';

export const OutOfHomeCampaignCreate = (props) => {

    return (
    <Create title="Add campaign" { ...props }>
        <SimpleForm redirect="list">

            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput multiline source="client_email" label="Client email (one per line)"/>

        </SimpleForm>
    </Create>
    );
};