// src/components/IdFieldWithCopy.js
import React from 'react';
import { TextField } from 'react-admin';
import Button from '@mui/material/Button';
import { useForm } from 'react-final-form';

const extractUUID = (value) => {
    if (!value || typeof value !== 'string') return ''; // Handle undefined or non-string values

    // Split the URL by '/' and take the last segment
    const segments = value.split('/');
    const lastSegment = segments[segments.length - 1];

    // Regular expression to validate and extract UUID
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(lastSegment) ? lastSegment : ''; // Return UUID if valid, else empty string
};

const IdFieldWithCopy = ({ source }) => {
    const form = useForm();
    const rawValue = form.getState().values[source] || ''; // Get raw value from form
    const idValue = extractUUID(rawValue); // Extract UUID part

    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            // Modern Clipboard API
            navigator.clipboard.writeText(idValue).catch((err) => {
                console.error('Failed to copy using Clipboard API: ', err);
            });
        } else {
            // Fallback for older browsers or non-secure contexts
            try {
                const textArea = document.createElement('textarea');
                textArea.value = idValue;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
            } catch (err) {
                console.error('Fallback copy failed: ', err);
            }
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <TextField source={source} />
            <Button variant="outlined" onClick={handleCopy}>
                Copy ID
            </Button>
        </div>
    );
};

export default IdFieldWithCopy;