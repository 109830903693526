// admin/src/Component/EnhancedConversion/Create.js

import React from 'react';
import {
    regex,
    Create,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';

const validateDomain = regex(/^[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/, 'Must be a valid domain. Do not add www or http prefix. Subdomains should be converted to main domain.');
const requiredDomain = [required(), validateDomain];

const consentDefaultValue = {
    cookie_consent_same_site: '/api/cookie-consent-same-sites/08fc2129-d301-4b6a-a7bb-22a3f7a65897',
}

export const EnhancedConversionCreate = (props) => {

    return (
    <Create title="Add enhanced conversion " { ...props }>
        <SimpleForm redirect="list" initialValues={consentDefaultValue}>

            <BooleanInput source="active" label="Active" defaultValue={true} />
            <TextInput source="name" label="Name" validate={ required() } />
            <TextInput source="url" label="URL" validate={ requiredDomain } />
            <TextInput source="data_layer" label="Data layer" validate={ required() } defaultValue="dataLayer" />

            <ReferenceInput label="SameSite" source="cookie_consent_same_site" reference="cookie-consent-same-sites" validate={ required() }>
                <SelectInput optionText="name" />
            </ReferenceInput>

        </SimpleForm>
    </Create>
    );
};