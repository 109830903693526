// admin/src/Component/OutOfHomeCampaign/Edit.js

import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    required,
    DateInput,
    BooleanInput
} from 'react-admin';
import IdFieldWithCopy from './IdFieldWithCopy';
import NameFieldWithCopy from './NameFieldWithCopy';

export const OutOfHomeCampaignEdit = (props) => {

    return (
    <Edit title="Edit campaign" { ...props }>
        <SimpleForm>
            <IdFieldWithCopy source="id" />
            <TextField source="created_by" />
            <BooleanInput source="added_to_dashboard" label="Added to dashboard" />

            <NameFieldWithCopy source="name" label="Name" validate={required()} />

            <DateInput source="start_date" label="Start date" />
            <DateInput source="end_date" label="End date" />
            <TextInput multiline source="client_email" label="Client email (one per line)" />

        </SimpleForm>
    </Edit>
    );
};
