// admin/src/Component/OutOfHomeCampaign/List.js
import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    BooleanField,
    Filter,
    TextInput,
    usePermissions
} from 'react-admin';

import UploadSpreadsheets from './UploadSpreadsheets';
import DownloadClientExport from "./DownloadClientExport";
import DownloadFaceIdExport from "./DownloadFaceIdExport";
import CreateDashboard from "./CreateDashboard";
import authProvider from "../../authProvider";
import RefreshSpotAnalyzer from "../SpotAnalyzer/RefreshSpotAnalyzer";

const ConsentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search name" source="name" alwaysOn />
    </Filter>
);

export const OutOfHomeCampaignList = (props) => {

    const {permissions} = usePermissions();

    return (
    <List filters={<ConsentFilter />} {...props} title="Out of home Abri" perPage={25} sort={{ field: 'updateDate' , order: 'DESC' }}>
        <Datagrid>

            <BooleanField source="ready" label="Ready" />
            <BooleanField source="added_to_dashboard" label="Dashboard" />

            <TextField source="name" label="Name" />
            <DateField source="start_date" locales="nl-NL"  />
            <DateField source="end_date" locales="nl-NL"  />
            <TextField source="created_by" />

            <DateField source="update_date" sortBy="updateDate" showTime locales="nl-NL"  />
            <EditButton />

            <UploadSpreadsheets />
            <DownloadClientExport />
            <DownloadFaceIdExport />

            {authProvider.isAuthorised(permissions, ['ROLE_ADMIN']) && <CreateDashboard />}

        </Datagrid>
    </List>);
};