// src/components/NameInputWithCopy.js
import React from 'react';
import { TextInput } from 'react-admin';
import Button from '@mui/material/Button';
import { useForm } from 'react-final-form';

const NameInputWithCopy = ({ source, validate, ...props }) => {
    const form = useForm();
    const nameValue = form.getState().values[source] || ''; // Get raw value from form

    const handleCopy = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            // Modern Clipboard API
            navigator.clipboard.writeText(nameValue).catch((err) => {
                console.error('Failed to copy using Clipboard API: ', err);
            });
        } else {
            // Fallback for older browsers or non-secure contexts
            try {
                const textArea = document.createElement('textarea');
                textArea.value = nameValue;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
            } catch (err) {
                console.error('Fallback copy failed: ', err);
            }
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <TextInput
                source={source}
                validate={validate}
                {...props} // Pass through any additional props like label
            />
            <Button
                variant="outlined"
                onClick={handleCopy}
                disabled={!nameValue} // Disable button if no value
            >
                Copy Name
            </Button>
        </div>
    );
};

export default NameInputWithCopy;