// admin/src/Component/SpotAnalyzer/Edit.js
import React, { useEffect, useState } from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    required,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    BooleanInput,
    TextField,
    SelectInput,
    FormDataConsumer,
    useDataProvider
} from 'react-admin';

import Grid from '@mui/material/Grid';
import { useForm } from 'react-final-form';
import IdFieldWithCopy from './IdFieldWithCopy';
import NameFieldWithCopy from './NameFieldWithCopy';

const SanitizedContainer = ({basePath, ...props}) => {
    return (
        <Grid {...props} />
    );
};

export const SpotAnalyzerEdit = (props) => {
    const dataProvider = useDataProvider();
    const [selectedData, setSelectedData] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleOnChange = async (e) => {

        if(e) {
            const response = await dataProvider.getOne('mbs-flights', { id: e });

            if(response && response.data) {
                setSelectedData(response.data);
            }
        }
    };

    return (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Settings">
                <SanitizedContainer container spacing={1} style={{ width: "100%" }}>
                    <Grid item xl={6}>
                <IdFieldWithCopy source="id" />

                <ReferenceInput label="Type" source="spot_analyzer_type" reference="spot-analyzer-types" sort={{ field: 'name' , order: 'ASC' }}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

                <NameFieldWithCopy source="name" label="Name" validate={required()} />

                <DateInput source="start_date" label="Start date" validate={required()} onChange={(e) => setStartDate(e.target.value)} />
                <DateInput source="end_date" label="End date" validate={required()} onChange={(e) => setEndDate(e.target.value)} />
                <NumberInput source="budget" label="Budget" step={1}  />

                <FormDataConsumer>
                    {({ formData, ...rest }) => {

                        const form = useForm();

                        useEffect(() => {
                            if(selectedData && form) {

                                form.change('start_date', selectedData.start_date);
                                form.change('end_date', selectedData.end_date);
                                form.change('name', selectedData.name);
                                form.change('target_audience',selectedData.target_audience);

                                if(selectedData.primary_target_grp > 0) {
                                    form.change('planned_grp', selectedData.primary_target_grp);
                                }
                                else {
                                    form.change('planned_grp', null);
                                }

                                if(selectedData.average_contact_freq > 0) {
                                    form.change('planned_contact_freq', selectedData.average_contact_freq);
                                }
                                else {
                                    form.change('planned_contact_freq', null);
                                }

                                if(selectedData.reach_one_plus > 0) {
                                    form.change('planned_reach_one_plus', selectedData.reach_one_plus);
                                }
                                else {
                                    form.change('planned_reach_one_plus', null);
                                }

                                if(selectedData.reach_three_plus > 0) {
                                    form.change('planned_reach_three_plus', selectedData.reach_three_plus);
                                }
                                else if(selectedData.reach_effective > 0) {
                                    form.change('planned_reach_three_plus', selectedData.reach_effective);
                                }
                                else {
                                    form.change('planned_reach_three_plus', null);
                                }
                            }
                        }, [selectedData, form]);

                        useEffect(() => {
                            if (startDate) {
                                const compareDate = new Date(startDate);
                                compareDate.setFullYear(compareDate.getFullYear() - 1);
                                form.change('compare_start_date', compareDate.toISOString().split('T')[0]);
                            }
                        }, [startDate]);

                        useEffect(() => {
                            if (endDate) {
                                const compareDate = new Date(endDate);
                                compareDate.setFullYear(compareDate.getFullYear() - 1);
                                form.change('compare_end_date', compareDate.toISOString().split('T')[0]);
                            }
                        }, [endDate]);

                        return (
                            <ReferenceInput perPage={10000} label="MBS flight" source="mbs_flight" reference="mbs-flights"
                                            sort={{field: 'name', order: 'ASC'}}
                                            filterToQuery={mbs_flights => ({combination: mbs_flights})}
                                            onChange={handleOnChange}  {...rest}>
                                <AutocompleteInput optionText="combination" translateChoice={false}/>
                            </ReferenceInput>
                        );
                    }}
                </FormDataConsumer>

                <ReferenceInput perPage={10000} label="Target audience" source="target_audience" reference="target-audiences" validate={ required() } filterToQuery={audience => ({ audience: audience })}>
                    <AutocompleteInput debounce={500} optionText="audience" translateChoice={false}  />
                </ReferenceInput>

                <ReferenceInput perPage={10000} label="Country" source="country" reference="countries" validate={ required() } sort={{ field: 'name' , order: 'ASC' }}>
                    <AutocompleteInput debounce={500} optionText="name" translateChoice={false} />
                </ReferenceInput>

                <NumberInput source="planned_grp" label="Planned GRP"  />
                <NumberInput source="planned_contact_freq" label="Planned contact freq."  />
                <NumberInput source="planned_reach_one_plus" label="Planned 1+ %"  />
                <NumberInput source="planned_reach_three_plus" label="Planned 3+ %"  />

                <TextInput source="dashboard_url" label="Google DataStudio dashboard url" />
                <TextInput source="import_file_id" label="Import File ID" />
                <TextInput multiline source="client_email" label="Client email (one per line)" />

                </Grid>
                <Grid item xl={6}>

                <FormDataConsumer>
                    {({ formData }) => formData.spot_analyzer_type === '/api/spot-analyzer-types/spot-analyzer' &&
                        <div>
                            <div className="ra-input"><NumberInput source="minutes" label="Minutes" /></div>
                            <div className="ra-input"><NumberInput source="baseline_percentage" label="Baseline percentage" /></div>
                            <BooleanInput source="assign_zero_grp_single_spot" label="Assign zero GRP single spots" />
                            <NumberInput source="grp_spot_limit" label="GRP spot limit" />

                            <div className="ra-input"><DateInput source="compare_start_date"  label="Compare start date" validate={ required() } /></div>
                            <div className="ra-input"><DateInput source="compare_end_date" label="Compare end date" validate={ required() }/></div>

                            <ReferenceInput  perPage={10000} label="Analytics view" source="analytics_view" reference="analytics-views" validate={ required() } filterToQuery={views => ({ combination: views })}>
                                <AutocompleteInput debounce={500} optionText="combination" translateChoice={false} />
                            </ReferenceInput>

                            <ReferenceInput  perPage={10000} label="Dutch Weather station" source="weather_station" reference="weather-stations" filterToQuery={stations => ({ name: stations })}>
                                <AutocompleteInput optionText="name" translateChoice={false} />
                            </ReferenceInput>

                            <SelectInput label="Temperature type" source="temperature_type" choices={[
                                { id: 'TN', name: 'Temperature (min)' },
                                { id: 'TG', name: 'Temperature (average)' },
                                { id: 'TX', name: 'Temperature (max)' }
                            ]} />

                        </div>
                    }
                </FormDataConsumer>
                </Grid>
                </SanitizedContainer>
            </FormTab>
        </TabbedForm>
    </Edit>
    );
};