// in src/SpotAnalyzer/UploadSpreadsheets.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import config from 'react-global-configuration';
import {CloudUpload} from "@material-ui/icons";

class UploadSpots extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}spot-analyzer/${record.originId}/spot-uploader`, '_self');
    }

    render() {

        const {record } = this.props;

        if (record.spot_analyzer_type == '/api/spot-analyzer-types/spot-analyzer') {

            return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
                <CloudUpload/> &nbsp; Upload spots
            </Button>;

        }
        else {
            return null;
        }
    }
}

UploadSpots.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(UploadSpots);