// in src/OutOfHomeCampaign/CreateDashboard.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import config from "react-global-configuration";

class CreateDashboard extends Component {

    handleClick = () => {
        const {record} = this.props;
        const reportId = 'b0dde631-d003-49a6-8227-98b626935842';
        const firstWord = (record.name && typeof record.name === 'string' ? record.name.trim().split(" ")[0] : '');
        const connectorName = firstWord + ' ' + new Date().getFullYear();
        const reportName = 'OOH - ' + connectorName;

        const totalsNr = '1. OOH - GoogleDataStudio connector - Locations - '
        const totals = 'ds47';
        const totalsImplementationId = 'AKfycbzizahB65T_hmQQfN7DnEBEFEJcpJSSz-DgrFU_5EJq0WcLht_SBXLP2OP71PjN0IiKGA';
        const totalsUrl = `&ds.${totals}.datasourceName=${totalsNr} ${connectorName}&ds.${totals}.connector=community&ds.${totals}.connectorId=${totalsImplementationId}&ds.${totals}.uuid=${record.originId}`;

        const resultsNr = '2. OOH - GoogleDataStudio connector - Results - '
        const results = 'ds48';
        const resultsImplementationId = 'AKfycbyZhzqs5l63duxSfcJuC7k3OUEtgMhIqxVcinJj7sIE4riipMHR7nCgfOgPyC1edIFY';
        const resultsUrl = `&ds.${results}.datasourceName=${resultsNr} ${connectorName}&ds.${results}.connector=community&ds.${results}.connectorId=${resultsImplementationId}&ds.${results}.uuid=${record.originId}`;

        const spotsNr = '3. OOH - GoogleDataStudio connector - Images - '
        const spots = 'ds60';
        const spotsImplementationId = 'AKfycbzMcU5P_nGPOtV14c3n_PFBAWuXD7jriEkoqGYEZgwmVl01yilg-K0EqwJdCLr-d9SW';
        const spotsUrl = `&ds.${spots}.datasourceName=${spotsNr} ${connectorName}&ds.${spots}.connector=community&ds.${spots}.connectorId=${spotsImplementationId}&ds.${spots}.uuid=${record.originId}`;

        window.open(`https://lookerstudio.google.com/reporting/create?c.reportId=${reportId}&r.reportName=${reportName}${totalsUrl}${resultsUrl}${spotsUrl}`, '_blank');
    }

    render() {
        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <FileCopyIcon /> &nbsp; New dashboard
        </Button>;
    }
}

CreateDashboard.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(CreateDashboard);