// in src/OutOfHomeCampaign/DownloadFaceIdSpreadsheet.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import config from 'react-global-configuration';
import {CloudDownload} from "@material-ui/icons";

class DownloadFaceIdExport extends Component {

    handleClick = () => {

        const webUrl = config.get('app.web_url');
        const {record } = this.props;
        window.open(`${webUrl}out_of_home/face-id/${record.originId}.zip`, '_self');
    }

    render() {

        const {record } = this.props;

        if (record.out_of_home_locations.length > 0) {

        return <Button variant="text" color="primary" size="small" onClick={this.handleClick}>
        <CloudDownload /> &nbsp; FaceId
        </Button>;

        }
        else {
            return null;
        }
    }
}

DownloadFaceIdExport.propTypes = {
    record: PropTypes.object,
};

export default connect(null, null)(DownloadFaceIdExport);